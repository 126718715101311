/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {FC} from 'react';

import {getFullDate} from 'Utils/commonFunctions';
import {useStyledThemeState} from 'common/theme';

import ConfirmButton from 'components/common/ConfirmButton';
import OutlineButton from 'components/common/OutlineButton';

import style from './ExchangeTicketModal.module.scss';
import OrangeX from 'assets/orangeX.svg';
import ContrastX from 'assets/contrastX.svg';
import QRcode from 'assets/qr-code-icon.svg';
import QRcodeContrast from 'assets/qr-code-icon-contrast.svg';
import ArrowRight from 'assets/orangeArrowRight.svg';
import ArrowRightContrast from 'assets/contrastArrowRight.svg';

import {IDownloadTicketResponseSingleTicket} from 'api/sprzedaz/interfaces';
import {useNavigate} from 'react-router-dom';
import {returnStationByCode} from '../../../../../Utils/commonFunctions';
import {useData} from 'Utils/DataContex';
import {useTranslation} from 'react-i18next';

// import {makeTicketsQuery as makeDicountQuery} from 'Utils/commonFunctions';

interface Props {
  primaryTicket: IDownloadTicketResponseSingleTicket;
  tickets: IDownloadTicketResponseSingleTicket[];
  closeModal: () => void;
  exchangeReservation: boolean;
}

const ExchangeTicketModal: FC<Props> = ({primaryTicket, tickets, closeModal, exchangeReservation}) => {
  const {t} = useTranslation();

  const {isContrastTheme} = useStyledThemeState();
  const {stationsList} = useData();
  const navigate = useNavigate();

  const returnStationFromNr = () => {
    const firstTicketTripPart = tickets[0]?.odcinki[0];

    return firstTicketTripPart.stacjaOdKod;
  };

  const returnStationToNr = () => {
    const lastTicketTripParts = tickets[tickets.length - 1]?.odcinki;
    const toNr = lastTicketTripParts[lastTicketTripParts.length - 1]?.stacjaDoKod;

    return toNr;
  };

  const returnExchangeQueryString = () => {
    // eslint-disable-next-line no-unsafe-optional-chaining
    const stations = primaryTicket?.odcinki;
    const fromNr = returnStationFromNr();
    const toNr = returnStationToNr();
    const date = stations[0]?.wyjazdData.split(' ')[0];
    const time = stations[0]?.wyjazdData.split(' ')[1];
    const travelers = primaryTicket?.podrozni;
    const travelersDecoded = travelers.map((x) => {
      return {...x, znizkaKod: x.znizkaKod * 10 + 1000};
    });

    const ticket50 = travelersDecoded
      ?.filter((x) => x.znizkaKod === 1770 || x.znizkaKod === 1710 || x.znizkaKod === 2760)
      .map((x) => {
        return x.znizkaKod;
      })
      .toString()
      .replace(/,/g, ';');
    const ticket100 = travelersDecoded
      ?.filter(
        (x) =>
          x.znizkaKod > 1 &&
          x.znizkaKod !== Number(1770) &&
          x.znizkaKod !== Number(1710) &&
          x.znizkaKod !== Number(2760),
      )
      .map((x) => `${x.znizkaKod}${x.spolkaKolejowaKod ? `,0,${x.spolkaKolejowaKod}` : ''}`)
      .join(';');

    return `tnr=${primaryTicket.transakcjaNr}&bsr=${primaryTicket.biletSeria}&bnr=${primaryTicket.biletNr}&dwyj=${date}&swyj=${fromNr}&sprzy=${toNr}&polbez=0&polnaj=0&lpol=0&cmax=1440&cmin=10&brail=0&time=${time}&ticket50=${ticket50}&ticket100=${ticket100}`;
  };

  return (
    <div className={style.exchange_ticket__modal}>
      <div className={style.exchange_ticket__modal_box}>
        <header className={style.exchange_ticket__modal_box_header}>
          <h2>{exchangeReservation ? t('29491') : t('29340')}</h2>
          <img src={isContrastTheme ? ContrastX : OrangeX} tabIndex={0} alt={t('29341')} onClick={closeModal} />
        </header>
        <div className={style.exchange_ticket__ticket_data}>
          <img src={isContrastTheme ? QRcodeContrast : QRcode} alt={t('29137')} />
          <div>
            <div className={style.ticket_row}>
              <p className={style.exchange_ticket__text_regular}>
                {primaryTicket?.odcinki[0]?.stacjaOdKod
                  ? returnStationByCode(returnStationFromNr(), stationsList)
                  : null}
                <span>
                  <img
                    className={style.exchange_ticket__arrow}
                    src={isContrastTheme ? ArrowRightContrast : ArrowRight}
                    alt={t('11027')}
                  />{' '}
                </span>
              </p>
              <p className={style.exchange_ticket__text_regular}>
                {primaryTicket?.odcinki && returnStationByCode(returnStationToNr(), stationsList)}
              </p>
            </div>
            <p className={style.exchange_ticket__text_regular}>{getFullDate(primaryTicket?.odcinki[0]?.wyjazdData)}</p>
            {tickets
              ? tickets.map(
                  (item) =>
                    !item.zwrotOpis && (
                      <p key={item.biletNr} className={style.exchange_ticket__text_regular}>
                        {item.biletSeria + item.biletNr}
                      </p>
                    ),
                )
              : null}
          </div>
        </div>
        <div className={style.exchange_ticket__info}>
          <div>
            <p className={style.exchange_ticket__text_blue}>{t('29028')}</p>
            <p className={style.exchange_ticket__text_regular}>{exchangeReservation ? t('29492') : t('29339')}</p>
          </div>
          <div>
            <p className={style.exchange_ticket__text_blue}>{t('26004')}</p>
            <p className={style.exchange_ticket__text_regular}>{exchangeReservation ? t('29493') : t('29337')}</p>
          </div>
        </div>
        <h3 className={style.exchange_ticket__bottom_txt}>{t('29146')}</h3>

        <div className={`${style.exchange_ticket__btn_box} ${style.btn_100}`}>
          <OutlineButton text={t('22006')} path={closeModal} />
          <ConfirmButton
            text={t('29338')}
            execute={() => {
              navigate(`/wymianabiletu?${returnExchangeQueryString()}`);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ExchangeTicketModal;
