import {useEffect} from 'react';

import {usePayment} from 'Utils/PaymentContext';
import usePaymentMethods from 'Utils/usePaymentMethods';
import PaymentDeadlineTimer from 'components/pages/PurchasePath/PaymentSummaryView/PaymentDeadlineTimer/PaymentDeadlineTimer';
import PaymentErrorModal from 'components/pages/PurchasePath/PaymentErrorModal/PaymentErrorModal';
import PaymentRedirectModal from 'components/pages/PurchasePath/PaymentRedirectModal/PaymentRedirectModal';
import MyTicketsSingleTicketUnpaidMobileInnerData from './MyTicketsSingleTicketUnpaidMobileInnerData';

import usePayLaterTicket from './usePayLaterTicket';

import style from './MyTickets.module.scss';
import {useTranslation} from 'react-i18next';
import {returnAdditionalTypeTicketData} from 'Utils/commonFunctions';
import {useStyledThemeState} from 'common/theme';
import {ticketKinds} from 'common/api/myTickets';

const MyTicketsSingleUnpaidTicket = () => {
  const {name, payByBlik, setPayByBlik, isPaymentModalOpen, setIsPaymentModalOpen, getTicket} = usePayLaterTicket();

  const {t} = useTranslation();
  const {isContrastTheme} = useStyledThemeState();

  const {ticketFrom, ticketTo, additionalTicketsTo, additionalTicketsFrom} = usePayment();

  const {registerPayment, setBlik, blik, specialModal, setSpecialModal, modalText, loader} = usePaymentMethods(
    () => {},
  );

  useEffect(() => {
    const tick = async () => {
      await getTicket();
    };

    tick();
  }, []);

  useEffect(() => {
    if (specialModal) {
      setIsPaymentModalOpen(false);
    }
  }, [specialModal]);

  const getFullPrice = (isBack?: boolean) => {
    let cost = 0;
    if (!isBack && ticketFrom) cost = cost + ticketFrom.cenaBilet + ticketFrom.cenaRowery;
    if (isBack && ticketTo) cost = cost + ticketTo.cenaBilet + ticketTo.cenaRowery;
    if (additionalTicketsFrom)
      cost =
        cost +
        additionalTicketsFrom.piesCena +
        additionalTicketsFrom.piesPrzewodnikCena +
        additionalTicketsFrom.bagazCena;
    if (additionalTicketsTo)
      cost =
        cost + additionalTicketsTo.piesCena + additionalTicketsTo.piesPrzewodnikCena + additionalTicketsTo.bagazCena;
    return (ticketFrom?.cena || ticketTo?.cena || cost) / 100;
  };

  return (
    <>
      <div className={`${style.ticket_container} `}>
        <div className={`${style.ticket_head_box} ${style.ticket_head_box_unpaid}`}>
          <div className={`${style.ticket_header} ${style.ticket_header_unpaid}`}>
            {' '}
            <p className={`${style.blue_text} ${style.blue_textLight}`}>{t('29178')}</p>
            <p className={`${style.navy_text_big} ${style.navy_text_light}`}>
              {ticketFrom?.transakcjeNr?.map((el, index) => {
                if (index === 0) return <span key={`${el.transakcjaNr}-${el.cena}`}>{el.transakcjaNr}</span>;
                else return <span key={`${el.transakcjaNr}-${el.cena}`}>, {el.transakcjaNr}</span>;
              }) || ticketFrom?.transakcjaNr}
              {ticketTo?.transakcjeNr?.map((el, index) => {
                if (index === 0) return <span key={`${el.transakcjaNr}-${el.cena}`}>{el.transakcjaNr}</span>;
                else return <span key={`${el.transakcjaNr}-${el.cena}`}>, {el.transakcjaNr}</span>;
              }) || ticketTo?.transakcjaNr}
            </p>
          </div>
          <div className={`${style.ticket_header} ${style.ticket_header_unpaid}`}>
            <p className={style.red_text}>{t('29179')}</p>
          </div>
          <div className={style.ticket_head_icons}>
            <PaymentDeadlineTimer
              isPayLater
              onPaymentDeadline={() => {
                window.localStorage.removeItem('payLaterTicket');
              }}
            />
          </div>
        </div>

        <div className={style.ticket_main_data_wrapper}>
          <div className={style.ticket_main_data_left_column_unpaid}>
            {ticketFrom && (
              <div className={style.ticket_main_data_left_column_content}>
                <MyTicketsSingleTicketUnpaidMobileInnerData
                  ticket={ticketFrom}
                  fullPrice={getFullPrice().toFixed(2).toString().replace('.', ',')}
                />
              </div>
            )}

            {ticketTo && (
              <div className={style.ticket_main_data_left_column_content}>
                <MyTicketsSingleTicketUnpaidMobileInnerData
                  ticket={ticketTo}
                  fullPrice={getFullPrice().toFixed(2).toString().replace('.', ',')}
                />
              </div>
            )}
          </div>
          <div className={style.ticket_main_data_right_column}>
            <button
              className={`col-sm-12 col-12 ${style.outline_button}`}
              onClick={(e) => {
                e.preventDefault();
                setPayByBlik(!payByBlik);
              }}
              disabled={loader}
            >
              BLIK
            </button>
            {payByBlik && (
              <div className={style.inputContainer}>
                <input
                  className={style.blikInpu}
                  type="number"
                  inputMode="numeric"
                  id="blickCode"
                  name="blickCode"
                  placeholder="Kod blik"
                  value={blik}
                  onChange={(e) => {
                    const blikNumber = e.target.value;
                    blikNumber.length <= 6 && setBlik(blikNumber.replace(/\D/g, ''));
                  }}
                />
                <button
                  className={`col-sm-12 col-12 ${style.outline_button}`}
                  onClick={(e) => {
                    e.preventDefault();
                    if (blik.length > 5) {
                      registerPayment('P24Blik', name);
                      setIsPaymentModalOpen(true);
                    }
                  }}
                  disabled={loader}
                >
                  ZAPŁAĆ
                </button>
              </div>
            )}
            <button
              className={`col-sm-12 col-12 ${style.outline_button}`}
              onClick={(e) => {
                e.preventDefault();
                registerPayment('P24', name);
                setIsPaymentModalOpen(true);
                setPayByBlik(false);
              }}
              disabled={loader}
            >
              {t('24094')}
            </button>
            <button
              className={`col-sm-12 col-12 ${style.outline_button}`}
              onClick={(e) => {
                e.preventDefault();
                registerPayment('P24Przelew', name);
                setIsPaymentModalOpen(true);
                setPayByBlik(false);
              }}
              disabled={loader}
            >
              {t('24095')}
            </button>
            <button
              className={`col-sm-12 col-12 ${style.outline_button}`}
              onClick={(e) => {
                e.preventDefault();
                window.localStorage.removeItem('payLaterTicket');
                window.location.reload();
              }}
              disabled={loader}
            >
              {t('24096')}
            </button>
          </div>
        </div>
        {additionalTicketsFrom && (
          <div className="">
            <div className={`${style.otherTicketsContainer}  `}>
              <p className={style.otherTicketTitle}>{t('29182')}</p>
              <div className={`${style.otherTicketsWrapper} `}>
                {!!additionalTicketsFrom.piesCena && (
                  <div className={`${style.singleAdditionalTicket} `}>
                    <img
                      src={returnAdditionalTypeTicketData(t, ticketKinds.DOG, isContrastTheme, 'light')?.image}
                      alt={returnAdditionalTypeTicketData(t, ticketKinds.DOG, isContrastTheme, 'light')?.nazwa}
                    />
                    <span>
                      {additionalTicketsFrom.piesCena / 1520}x{' '}
                      {returnAdditionalTypeTicketData(t, 21, isContrastTheme, 'light')?.nazwa}
                    </span>
                  </div>
                )}
                {!!additionalTicketsFrom.piesPrzewodnikCena && (
                  <div className={`${style.singleAdditionalTicket} `}>
                    <img
                      src={returnAdditionalTypeTicketData(t, ticketKinds.GUIDE_DOG, isContrastTheme, 'light')?.image}
                      alt={returnAdditionalTypeTicketData(t, ticketKinds.GUIDE_DOG, isContrastTheme, 'light')?.nazwa}
                    />
                    <span>
                      {additionalTicketsFrom.piesPrzewodnikCena / 355}x{' '}
                      {returnAdditionalTypeTicketData(t, ticketKinds.GUIDE_DOG, isContrastTheme, 'light')?.nazwa}
                    </span>
                  </div>
                )}
                {!!additionalTicketsFrom.bagazCena && (
                  <div className={`${style.singleAdditionalTicket} `}>
                    <img
                      src={returnAdditionalTypeTicketData(t, ticketKinds.LUGGAGE, isContrastTheme, 'light')?.image}
                      alt={returnAdditionalTypeTicketData(t, ticketKinds.LUGGAGE, isContrastTheme, 'light')?.nazwa}
                    />
                    <span>
                      {additionalTicketsFrom.bagazCena / 510}x{' '}
                      {returnAdditionalTypeTicketData(t, ticketKinds.LUGGAGE, isContrastTheme, 'light')?.nazwa}
                    </span>
                  </div>
                )}
                {!!ticketFrom?.cenaRowery && (
                  <div className={`${style.singleAdditionalTicket} `}>
                    <img
                      src={returnAdditionalTypeTicketData(t, ticketKinds.BICYCLE, isContrastTheme, 'light')?.image}
                      alt={returnAdditionalTypeTicketData(t, ticketKinds.BICYCLE, isContrastTheme, 'light')?.nazwa}
                    />
                    <span>
                      {ticketFrom?.cenaRowery / 910}x{' '}
                      {returnAdditionalTypeTicketData(t, ticketKinds.BICYCLE, isContrastTheme, 'light')?.nazwa}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>

      {specialModal && <PaymentErrorModal htmlMessage={modalText} isPayLaterTicket closeFn={setSpecialModal} />}
      {isPaymentModalOpen && (
        <PaymentRedirectModal
          loaderMessage={payByBlik ? '24102' : '24003'}
          onClose={() => setIsPaymentModalOpen(false)}
        />
      )}
    </>
  );
};

export default MyTicketsSingleUnpaidTicket;
