import {SEASONAL_OFFER_CODES} from './seasonalOfferCodes';

export const OFFER_CODES = {
  UNAVAILABLE: 0,
  THERE_AND_BACK: 1, //For domestic ticket - "PODRÓŻ TAM", international ticket - TP
  IRT: 2,
  NRT: 3,
  FAMILY_TICKET_OR_SUPER_PROMO_INTERNATIONAL: 4, //For domestic ticket - FAMILY_TICKET, international ticket - SPI
  INTERNATIONAL_TICKET: 8,
  DOG: 10,
  BETTER_WITH_FAMILY: 14,
  LARGE_FAMILY_CARD: 15,
  BIKE: 603,
  LUGGAGE: 630,
  INTERCITY_CARD_RESERVATION: 4998,
  GUIDE_DOG: 9998,
  ...SEASONAL_OFFER_CODES,
} as const;
