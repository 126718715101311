import {IconDefinition} from './Icon.types';

import {ReactComponent as TripThereIcon} from 'assets/tripThereOrangeDesktop.svg';
import {ReactComponent as TripBackIcon} from 'assets/tripBackOrangeDesktop.svg';
import {ReactComponent as TripThereIconContrast} from 'assets/tripThereContrastDesktop.svg';
import {ReactComponent as TripBackIconContrast} from 'assets/tripBackContrastDesktop.svg';
import {ReactComponent as CalendarIcon} from 'assets/calendar.svg';
import {ReactComponent as CalendarIconContrast} from 'assets/calendarContrast.svg';
import {ReactComponent as SpecialCalendar} from 'assets/specialCalendar.svg';
import {ReactComponent as SpecialCalendarContrast} from 'assets/specialCalendarContrast.svg';
import {ReactComponent as Info} from 'assets/infoIcon.svg';
import {ReactComponent as InfoContrast} from 'assets/contrastInfoIcon.svg';
import {ReactComponent as Exclamation} from 'assets/exclamation.svg';
import {ReactComponent as ExclamationNavy} from 'assets/exclamation-navy.svg';
import {ReactComponent as ExclamationInfo} from 'assets/exclamation-info.svg';
import {ReactComponent as ExclamationContrast} from 'assets/exclamationContrast.svg';
import {ReactComponent as Checked} from 'assets/orangeCheckboxIcon.svg';
import {ReactComponent as CheckedContrast} from 'assets/contrastCheckboxIcon.svg';
import {ReactComponent as OrangeArrowLeft} from 'assets/orangeArrowLeft.svg';
import {ReactComponent as OrangeArrowLeftContrast} from 'assets/contrastArrowLeft.svg';
import {ReactComponent as X} from 'assets/orangeX.svg';
import {ReactComponent as XContrast} from 'assets/contrastX.svg';
import {ReactComponent as chevronLeft} from 'assets/chevronLeft.svg';
import {ReactComponent as chevronLeftContrast} from 'assets/chevronLeftContrast.svg';
import {ReactComponent as Pencil} from 'assets/orangePencil.svg';
import {ReactComponent as PencilContrast} from 'assets/contrastPencil.svg';
import {ReactComponent as Gear} from 'assets/gear.svg';
import {ReactComponent as GearContrast} from 'assets/contrastGear.svg';
import {ReactComponent as Preloader} from 'assets/preloader.svg';
import {ReactComponent as CardArrowIcon} from 'assets/cardArrow.svg';
import {ReactComponent as CardArrowIconContrast} from 'assets/cardArrowContrast.svg';
import {ReactComponent as TicketIcon} from 'assets/ticket.svg';
import {ReactComponent as TicketIconContrast} from 'assets/contrastTicket.svg';
import {ReactComponent as OrangeTicketIcon} from 'assets/orangeTicket.svg';
import {ReactComponent as PassengerIcon} from 'assets/profile.svg';
import {ReactComponent as PassengerIconContrast} from 'assets/profileContrastDesktop2.svg';
import {ReactComponent as ClassIcon} from 'assets/class.svg';
import {ReactComponent as ClassIconContrast} from 'assets/contrastClass.svg';
import {ReactComponent as ShoppingBasket} from 'assets/Basket.svg';
import {ReactComponent as ShoppingBasketContrast} from 'assets/BasketContrast.svg';
import {ReactComponent as ArrowRightIcon} from 'assets/orangeArrowRightBig.svg';
import {ReactComponent as ArrowRightIconContrast} from 'assets/contrastArrowRightBig.svg';
import {ReactComponent as HomeIcon} from 'assets/home.svg';
import {ReactComponent as HomeIconContrast} from 'assets/homeContrast.svg';
import {ReactComponent as TrainOnRails} from 'assets/trainOnRails.svg';
import {ReactComponent as TrainOnRailsContrast} from 'assets/trainOnRailsContrast.svg';
import {ReactComponent as ManOnArmchair} from 'assets/manOnArmchairNavy.svg';
import {ReactComponent as ManOnArmchairContrast} from 'assets/manOnArmchairContrast.svg';
import {ReactComponent as ManOnWheelChair} from 'assets/SeatOptions/oznWheelchair.svg';
import {ReactComponent as ManOnWheelChairContrast} from 'assets/SeatOptions/oznWheelchairContrast.svg';
import {ReactComponent as ShoppingBasketThick} from 'assets/basketThick.svg';
import {ReactComponent as ShoppingBasketThickContrast} from 'assets/basketThickContrast.svg';
import {ReactComponent as CheckboxWithBackground} from 'assets/checkboxWithBackground.svg';
import {ReactComponent as CheckboxWithBackgroundContrast} from 'assets/checkboxWithBackgroundContrast.svg';
import {ReactComponent as UserIcon} from 'assets/userIcon.svg';
import {ReactComponent as UserIconDark} from 'assets/userIconDark.svg';
import {ReactComponent as UserIconContrast} from 'assets/userIconContrast.svg';
import {ReactComponent as ArrowRightBlue} from 'assets/navyArrowRight.svg';
import {ReactComponent as Clock} from 'assets/clock.svg';
import {ReactComponent as ClockContrast} from 'assets/clockContrast.svg';
import {ReactComponent as TripThereNavyIcon} from 'assets/tripThereMobile.svg';
import {ReactComponent as TripBackNavyIcon} from 'assets/tripBackMobile.svg';
import {ReactComponent as Qr} from 'assets/qr-code-icon.svg';
import {ReactComponent as QrContrast} from 'assets/qr-code-icon-contrast.svg';
import {ReactComponent as Magnifier} from 'assets/magnifier.svg';
import {ReactComponent as MagnifierContrast} from 'assets/magnifierContrast.svg';
import {ReactComponent as Wagon} from 'assets/wagon.svg';
import {ReactComponent as WagonContrast} from 'assets/wagonContrast.svg';
import {ReactComponent as Add} from 'assets/mathSymbols/plus.svg';
import {ReactComponent as AddContrast} from 'assets/mathSymbols/plusContrast.svg';
import {ReactComponent as AddDisabled} from 'assets/mathSymbols/plusGrey.svg';
import {ReactComponent as Minus} from 'assets/mathSymbols/minus.svg';
import {ReactComponent as MinusContrast} from 'assets/mathSymbols/minusContrast.svg';
import {ReactComponent as MinusDisabled} from 'assets/mathSymbols/minusGrey.svg';
import {ReactComponent as BikeIcon} from 'assets/AdditionalTickets/bicycle.svg';
import {ReactComponent as DogIcon} from 'assets/AdditionalTickets/pet.svg';
import {ReactComponent as LuggageIcon} from 'assets/AdditionalTickets/luggage.svg';
import {ReactComponent as BikeIconContrast} from 'assets/AdditionalTickets/bicycleContrast.svg';
import {ReactComponent as DogIconContrast} from 'assets/AdditionalTickets/petContrast.svg';
import {ReactComponent as LuggageIconContrast} from 'assets/AdditionalTickets/luggageContrast.svg';
import {ReactComponent as OrangeArrowBottomIcon} from 'assets/orangeArrowBottomDesktop.svg';
import {ReactComponent as ContrastArrowBottomIcon} from 'assets/contrastArrowBottomDesktop.svg';
import {ReactComponent as OrangeArrowUpIcon} from 'assets/orangeArrowUp.svg';
import {ReactComponent as ContrastArrowUpIcon} from 'assets/contrastArrowUp.svg';
import {ReactComponent as SittingPlace} from 'assets/SeatOptions/sittingPerson.svg';
import {ReactComponent as SittingPlaceContrast} from 'assets/SeatOptions/sittingPersonContrast.svg';
import {ReactComponent as LyingPlace} from 'assets/SeatOptions/sleep4.svg';
import {ReactComponent as LyingPlaceContrast} from 'assets/SeatOptions/sleep4Contrast.svg';
import {ReactComponent as SleepingPlace} from 'assets/SeatOptions/sleep6.svg';
import {ReactComponent as SleepingPlaceContrast} from 'assets/SeatOptions/sleep6Contrast.svg';
import {ReactComponent as GreyInfo} from 'assets/informationIcon.svg';
import {ReactComponent as DarkBlueInfo} from 'assets/darkBlueInfo.svg';
import {ReactComponent as TrashBin} from 'assets/trashBinIcon.svg';
import {ReactComponent as TrashBinContrast} from 'assets/trashBinIconContrast.svg';
import {ReactComponent as TrashBinDisabled} from 'assets/trashBinIconDisabled.svg';

export const ICONS_DEFINITION: IconDefinition = {
  arrow: {
    normal: ArrowRightIcon,
    contrast: ArrowRightIconContrast,
  },
  home: {
    normal: HomeIcon,
    contrast: HomeIconContrast,
  },
  tripThere: {
    normal: TripThereIcon,
    contrast: TripThereIconContrast,
  },
  tripBack: {
    normal: TripBackIcon,
    contrast: TripBackIconContrast,
  },
  calendar: {
    normal: CalendarIcon,
    contrast: CalendarIconContrast,
  },
  specialCalendar: {
    normal: SpecialCalendar,
    contrast: SpecialCalendarContrast,
  },
  exclamation: {
    normal: Exclamation,
    contrast: ExclamationContrast,
  },
  exclamationNavy: {
    normal: ExclamationNavy,
    contrast: ExclamationContrast,
  },
  exclamationInfo: {
    normal: ExclamationInfo,
    contrast: ExclamationContrast,
  },
  info: {
    normal: Info,
    contrast: InfoContrast,
  },
  checked: {
    normal: Checked,
    contrast: CheckedContrast,
  },
  arrowLeft: {
    normal: OrangeArrowLeft,
    contrast: OrangeArrowLeftContrast,
  },
  X: {
    normal: X,
    contrast: XContrast,
  },
  chevronLeft: {
    normal: chevronLeft,
    contrast: chevronLeftContrast,
  },
  pencil: {
    normal: Pencil,
    contrast: PencilContrast,
  },
  gear: {
    normal: Gear,
    contrast: GearContrast,
  },
  preloader: {
    normal: Preloader,
    contrast: Preloader,
  },
  arrowCard: {
    normal: CardArrowIcon,
    contrast: CardArrowIconContrast,
  },
  ticketIcon: {
    normal: TicketIcon,
    contrast: TicketIconContrast,
  },
  orangeTicketIcon: {
    normal: OrangeTicketIcon,
    contrast: OrangeTicketIcon,
  },
  passengerIcon: {
    normal: PassengerIcon,
    contrast: PassengerIconContrast,
  },
  classIcon: {
    normal: ClassIcon,
    contrast: ClassIconContrast,
  },
  shoppingBasket: {
    normal: ShoppingBasket,
    contrast: ShoppingBasketContrast,
  },
  trainOnRails: {
    normal: TrainOnRails,
    contrast: TrainOnRailsContrast,
  },
  manOnArmchair: {
    normal: ManOnArmchair,
    contrast: ManOnArmchairContrast,
  },
  manOnWheelChair: {
    normal: ManOnWheelChair,
    contrast: ManOnWheelChairContrast,
  },
  shoppingBasketThick: {
    normal: ShoppingBasketThick,
    contrast: ShoppingBasketThickContrast,
  },
  checkboxWithBackground: {
    normal: CheckboxWithBackground,
    contrast: CheckboxWithBackgroundContrast,
  },
  userIcon: {
    normal: UserIcon,
    contrast: UserIconContrast,
  },
  userIconDark: {
    normal: UserIconDark,
    contrast: UserIconContrast,
  },
  arrowBlue: {
    normal: ArrowRightBlue,
    contrast: ArrowRightIconContrast,
  },
  clock: {
    normal: Clock,
    contrast: ClockContrast,
  },
  tripThereNavy: {
    normal: TripThereNavyIcon,
    contrast: TripThereIconContrast,
  },
  tripBackNavy: {
    normal: TripBackNavyIcon,
    contrast: TripBackIconContrast,
  },
  QR: {
    normal: Qr,
    contrast: QrContrast,
  },
  magnifier: {
    normal: Magnifier,
    contrast: MagnifierContrast,
  },
  wagon: {
    normal: Wagon,
    contrast: WagonContrast,
  },
  add: {
    normal: Add,
    contrast: AddContrast,
  },
  minus: {
    normal: Minus,
    contrast: MinusContrast,
  },
  addDisabled: {
    normal: AddDisabled,
    contrast: AddDisabled,
  },
  minusDisabled: {
    normal: MinusDisabled,
    contrast: MinusDisabled,
  },
  bike: {
    normal: BikeIcon,
    contrast: BikeIconContrast,
  },
  dog: {
    normal: DogIcon,
    contrast: DogIconContrast,
  },
  luggage: {
    normal: LuggageIcon,
    contrast: LuggageIconContrast,
  },
  orangeArrowBottomDesktop: {
    normal: OrangeArrowBottomIcon,
    contrast: ContrastArrowBottomIcon,
  },
  orangeArrowUp: {
    normal: OrangeArrowUpIcon,
    contrast: ContrastArrowUpIcon,
  },
  sittingPlace: {
    normal: SittingPlace,
    contrast: SittingPlaceContrast,
  },
  lyingPlace: {
    normal: LyingPlace,
    contrast: LyingPlaceContrast,
  },
  sleepingPlace: {
    normal: SleepingPlace,
    contrast: SleepingPlaceContrast,
  },
  greyInfo: {
    normal: GreyInfo,
    contrast: InfoContrast,
  },
  darkBlueInfo: {
    normal: DarkBlueInfo,
    contrast: InfoContrast,
  },
  trashBin: {
    normal: TrashBin,
    contrast: TrashBinContrast,
  },
  trashBinDisabled: {
    normal: TrashBinDisabled,
    contrast: TrashBinContrast,
  },
};
