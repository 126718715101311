import {FC, lazy} from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import TicketProvider from 'Utils/TicketContext';
import {GRMContextProvider} from 'Utils/grmContext/GrmContext';
import SearchProvider from 'Utils/SearchContext';
import PaymentProvider from 'Utils/PaymentContext';

const LazyChoiceOfPlace = lazy(() => import('components/pages/PurchasePath/ChoiceOfPlace/ChoiceOfPlace'));
const LazyPaymentSummary = lazy(
  () => import('components/pages/PurchasePath/PaymentSummaryView/PaymentSummary/PaymentSummary'),
);
const LazyPayment = lazy(() => import('components/pages/PurchasePath/Payment/Payment'));
const LazyTicketExchangeMain = lazy(() => import('components/pages/PurchasePath/TicketsExchange'));
const LazyPurchasePath = lazy(() => import('components/pages/PurchasePath'));
const LazyConnectionsList = lazy(() => import('common/pages/ConnectionsList'));

export const SingleFareTicketRouting: FC = () => {
  return (
    <PaymentProvider>
      <SearchProvider>
        <GRMContextProvider>
          <TicketProvider>
            <Routes>
              <Route index element={<LazyPurchasePath />} />
              <Route path="/wyszukiwanie" element={<LazyConnectionsList />} />
              <Route path="/wymianabiletu" element={<LazyTicketExchangeMain searchMode={false} />} />
              <Route path="/wymianabiletu/wyszukiwanie" element={<LazyTicketExchangeMain searchMode={true} />} />
              <Route path="/wybormiejsc" element={<LazyChoiceOfPlace />} />
              <Route path="/podsumowanie" element={<LazyPaymentSummary />} />
              <Route path="/platnosc" element={<LazyPayment />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </TicketProvider>
        </GRMContextProvider>
      </SearchProvider>
    </PaymentProvider>
  );
};
